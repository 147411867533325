.container {
  width: 100%;
  padding: 20px;
  background-color: #f5f7fa;
  min-height: calc(100vh - 60px);
}

.header {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.title {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section {
  width: 100%;
  margin-bottom: 30px;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.sectionTitle {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cardContent {
  padding: 25px;
}

.successMessage {
  background-color: #d4edda;
  color: #155724;
  padding: 12px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  border-left: 4px solid #28a745;
  display: flex;
  align-items: center;
  
  &::before {
    content: "✓";
    font-weight: bold;
    margin-right: 10px;
    font-size: 16px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputWrapper {
  margin-bottom: 15px;
  
  p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #333;
  }
}

.buttonWrapper {
  margin-top: 10px;
}

.label {
  font-weight: 500;
  color: #333;
  font-size: 14px;
}

.fileInputWrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.fileInput {
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  transition: border-color 0.2s;
  
  &:hover:not(:disabled) {
    border-color: #007bff;
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  &::-webkit-file-upload-button {
    visibility: hidden;
    width: 0;
  }
  
  &::before {
    content: 'Izaberite fajl';
    display: inline-block;
    background: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 10px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
  }
}

.fileName {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
  background-color: #f0f8ff;
  padding: 8px 12px;
  border-radius: 4px;
  border-left: 3px solid #007bff;
}

.submitButton {
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  align-self: flex-start;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  gap: 8px;

  &:hover:not(:disabled) {
    background-color: #0069d9;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
}

.loadingSpinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
} 